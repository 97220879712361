<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">我的钱包</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>我的钱包</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-30">
      <div class="purseBox_cont">
        <div class="text-white flex align-center justify-between mb-15">
          <div class="font-14"></div>
          <div class="cursorp" @click="gomyDetail">我的明细</div>
        </div>
        <div class="flex align-center mb-35">
          <div class="x-2 flex flex-column align-center justify-center">
            <div class="font-42 font-weight-bolder text-white mb-15">
              {{ info.balance }}
            </div>
            <div class="text-white">余额（可提现）</div>
          </div>
          <div class="x-2 flex flex-column align-center justify-center">
            <div class="font-42 font-weight-bolder text-white mb-15">
              {{ info.consumption }}
            </div>
            <div class="text-white">消费金（不可提现）</div>
          </div>
        </div>
        <div class="flex align-center">
          <div
            class="x-2 text-white flex align-center justify-center cursorp"
            @click="gowithdrawdeposit"
          >
            <!-- <img
              src="@/assets/img/tixian.png"
              width="1.3125rem"
              height="1.3125rem"
              class="mr-15"
              alt=""
            /> -->
            <el-image
              class="mr-15"
              style="width: 1.3125rem; height: 1.3125rem"
              :src="require('@/assets/img/tixian.png')"
              fit="cover"
            ></el-image>
            提现
          </div>
          <div
            class="x-2 text-white flex align-center justify-center cursorp"
            @click="gotopUp"
          >
            <!-- <img
              src="@/assets/img/chongzhi2.png"
              width="1.3125rem"
              height="1.3125rem"
              class="mr-15"
              alt=""
            /> -->
            <el-image
              class="mr-15"
              style="width: 1.3125rem; height: 1.3125rem"
              :src="require('@/assets/img/chongzhi2.png')"
              fit="cover"
            ></el-image>
            充值
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      info: "",
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    // 跳转充值
    gotopUp() {
      this.$router.push({
        path: "/topUp",
        query: {type: "topUp",},
      });
    },
    // 跳转提现
    gowithdrawdeposit() {
      this.$router.push({
        path: "/withdrawdeposit",
        query: {type: "withdrawdeposit",},
      });
    },
    // 跳转我的明细
    gomyDetail() {
      this.$router.push({
        path: "/myDetail",
        query: {type: "myDetail",},
      });
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/wallet", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.info = res.data;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;

  &_cont {
    padding: 1.125rem 1.75rem 1.375rem;
    box-sizing: border-box;
    width: 27.875rem;
    // height: 12.5625rem;
    background-image: url("../../../assets/img/qianbaobeijin.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
